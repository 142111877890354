import React, { useEffect, useState } from "react";
import Navbar from "../../component/common/NavbarProfile";
import Success from "../../assets/images/SuccessFull.png";
import { toast } from "react-toastify";
import {
  CheckIfPaymentAlreadyDone,
  Create_si_and_payment_api,
  Create_si_and_payment_api_for_iframe,
  Submit_si_pmt_entry_store_payerid_touser,
  downloadInvoiceHelper,
  get_si_form_tran,
  handleRedirect,
} from "../../handlers/Payment/requestpayment";
import { useParams } from "react-router-dom";
import { Invoice } from "../../handlers/common/api";

const SuccessfullPage = () => {
  const [showPaymentSuccess, setShowPaymentSuccess] = useState(false);
  const [showPaymentFailed, setShowPaymentFailed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [venueData, setVenueData] = useState([]);
  const [callonce, setcallonce] = useState(true); //default should br true
  //const [flag, setFlag] = useState(0); // Changed to use state // change back to normal variable
  const { platform, id } = useParams();

  let flag = 0; // default should be 0

  useEffect(() => {
    const venue_data = JSON.parse(localStorage.getItem("venueData")) || {};
    setVenueData(venue_data);
  }, []);
  useEffect(() => {
    if (showPaymentSuccess) {
      localStorage.removeItem("cartItems");
    }
  }, [showPaymentSuccess]);

  // const create_si = async (firstPart, data) => {
  //   const token = localStorage.getItem("token");

  //   const venue_data = JSON.parse(localStorage.getItem("venueData")) || {};
  //   setVenueData(venue_data);
  //   const SI_Payload = {
  //     si_details: {
  //       order_number: firstPart,
  //       booking_for: venue_data?.booking_for,
  //       venue: venue_data?.data?.name,
  //       payment_gateway_response: "Success",
  //       mid_number: data.mid,
  //       transaction_id: data.transaction_id,
  //       payer_id: data.payer_id,
  //       card_first6: data.first_6,
  //       card_last4: data.last_4,
  //     },
  //   };

  //   try {
  //     if (callonce) {
  //       if (platform === "0") {
  //         const response = await Create_si_and_payment_api(SI_Payload, token);

  //         if (response) {
  //           toast.success("Order has been Created!");
  //           console.log(response);
  //           setcallonce(false);
  //           localStorage.removeItem("cartItems");
  //           localStorage.removeItem("countdownEndTime");
  //           localStorage.setItem("SiNo", response.data.data.si_number);
  //         }
  //       } else if (platform === "1") {
  //         let payer_id = localStorage.getItem("payer_id");
  //         let SI_Payer_Payload = {
  //           ...SI_Payload.si_details,
  //           si_number: id,
  //           payer_id,
  //         };
  //         // console.log("new payload",SI_Payer_Payload);
  //         const response = await Submit_si_pmt_entry_store_payerid_touser(
  //           SI_Payer_Payload
  //         );

  //         if (response) {
  //           toast.success("Order has been Created!");
  //           console.log("response 1", response);
  //           setcallonce(false);
  //           localStorage.removeItem("cartItems");
  //           localStorage.removeItem("countdownEndTime");
  //           localStorage.setItem("SiNo", response?.data?.data?.si_number);
  //         }
  //       } else if (platform === "2") {
  //         const response = await Create_si_and_payment_api_for_iframe(
  //           SI_Payload
  //         );

  //         if (response) {
  //           toast.success("Order has been Created!");
  //           console.log(response);
  //           setcallonce(false);
  //           localStorage.removeItem("cartItems");
  //           localStorage.removeItem("countdownEndTime");
  //           localStorage.setItem("SiNo", response?.data?.data?.si_number);
  //         }
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     toast.error("Failed to create order");
  //   }
  // };

  const fetchTransactionDetails = async (intervalId2) => {
    const token = localStorage.getItem("token");
    let transaction_id = "";
    if (typeof window !== "undefined" && window.location.search) {
      const searchParams = new URLSearchParams(window.location.search);
      if (searchParams.has("transaction_id")) {
        transaction_id = searchParams.get("transaction_id");
        const [firstPart] = transaction_id.split("_");

        const payload = {
          request: {
            transaction_id,
          },
        };

        if (platform === "3") {
          const last_4 = localStorage.getItem("last_4");
          const first_6 = localStorage.getItem("first_6");
          const payer_id = localStorage.getItem("payer_id");
          const transaction_id = localStorage.getItem("transaction_id");
          const mid = localStorage.getItem("request_mid");

          const token = localStorage.getItem("token");

          const venue_data =
            JSON.parse(localStorage.getItem("venueData")) || {};
          setVenueData(venue_data);
          const SI_Payload = {
            si_details: {
              order_number: firstPart,
              booking_for: venue_data?.booking_for,
              venue: venue_data?.data?.name,
              payment_gateway_response: "Success",
              mid_number: mid,
              transaction_id: transaction_id,
              payer_id: payer_id,
              card_first6: first_6,
              card_last4: last_4,
            },
          };

          const res2 = await CheckIfPaymentAlreadyDone(firstPart);
          if (res2.data.data.payment_status === 1) {
            setIsLoading(false);
            setShowPaymentSuccess(true);
            clearInterval(intervalId2);
          }
          console.log();
          // if (res2.data.data.payment_status === 0 && flag === 0 && callonce) {
          //   flag = 1;
          //   const response = await Create_si_and_payment_api(SI_Payload, token);

          //   if (response) {
          //     toast.success("Order has been Created!");
          //     console.log(response);
          //     setcallonce(false);
          //     localStorage.removeItem("cartItems");
          //     localStorage.removeItem("countdownEndTime");
          //     localStorage.setItem("SiNo", response.data.data.si_number);
          //     setShowPaymentSuccess(true);

          //     setIsLoading(false);
          //   }
          // }
        } else {
          const res = await handleRedirect(payload);
          // console.log("res",res.data.message.transaction_res.payer_id);
          if (res) {
            localStorage.setItem(
              "payer_id",
              res.data.message.transaction_res.payer_id
            );
          }

          if (
            res.data.message.transaction_res.response_msg === "successful" &&
            res.data.message.signature_msg ===
              "signature is fine, continue processing the request" &&
            res.data.message.transaction_res.response_code === "0"
          ) {
            clearInterval(intervalId2);

            setIsLoading(false);
            setShowPaymentSuccess(true);
            if (platform === "1") {
              // create_si(firstPart, res.data.message.transaction_res);
            } else {
              const res2 = await CheckIfPaymentAlreadyDone(firstPart, token);
              if (
                (res2.data.data.payment_status === 1 && flag === 0) ||
                res2.data.data.payment_status === null
              ) {
                // create_si(firstPart, res.data.message.transaction_res);

                flag = 1;
                clearInterval(intervalId2);
              }
            }
          } else {
            flag = 100;
            setShowPaymentFailed(true);
            setIsLoading(false);
            toast.error("Transaction Failed");
            clearInterval(intervalId2);
          }
        }
      }
    } else {
      setIsLoading(false);
    }
  };

  const get_si_from_transa = async (intervalId = null) => {
    const searchParams = new URLSearchParams(window.location.search);

    const transaction_id = searchParams.get("transaction_id");
    const res2 = await get_si_form_tran(transaction_id);
    if (res2?.data?.data) {
      // create_si(firstPart, res.data.message.transaction_res);
      localStorage.setItem("SiNo", res2.data.data?.si_number);
      flag = 1;
      clearInterval(intervalId);
    }
  };

  const downloadInvoice = async () => {
    const SiNo = localStorage.getItem("SiNo");

    try {
      if (SiNo) {
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = Invoice + `&name=${SiNo}`;
        a.download = "invoice.pdf";
        a.target = "_blank";
        a.rel = "noopener noreferrer";
        document.body.appendChild(a);

        // Trigger a click event on the anchor to start the download
        a.click();

        // Clean up: remove the anchor element
        document.body.removeChild(a);

        toast.success("Invoice Downloaded!");
        localStorage.removeItem("cartItems");
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed to Download Invoice");
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      localStorage.removeItem("role_name");
    }
    const intervalId2 = setInterval(() => {
      fetchTransactionDetails(intervalId2);
    }, 1000);
    const intervalId = setInterval(() => {
      get_si_from_transa(intervalId);
    }, 1000);
  }, []);
  useEffect(() => {
    const timer = setTimeout(() => {
      get_si_from_transa();
      // You can put any code you want to execute after 2 seconds here
    }, 2000);

    // Clean up the timer if the component unmounts
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Navbar />
      {isLoading ? (
        <div className="flex justify-center items-center h-screen">
          <div className="loader"> Loading...</div>
        </div>
      ) : showPaymentFailed ? (
        showPaymentFailed && (
          <div className="max-w-full justify-center items-center flex flex-col my-6 ">
            <div className="text-center mt-14">
              <h1>Payment Falied</h1>

              {console.log("Failed")}
              {/* <p>Booking done Successfully!</p> */}
            </div>
            {/* <div className="relative bg-white mt-10 p-6 rounded-md border border-gray-300 max-w-md mx-auto">
            <div className="text-center">
              <div className="flex flex-col items-center justify-center">
                <div className="rounded-full bg-[#F6E0E1] flex justify-center items-center w-24 h-24">
                  <img
                    src={Success}
                    width={90}
                    height={20}
                    className=""
                    alt="Success"
                  />
                </div>
                <h3 className="mt-4"> Done!</h3>
                <span className="mt-2 mb-8">Booking done Successfully! </span>
                <button
                  type="button"
                  className="px-4 py-2 text-sm font-semibold text-white download-invoice"
                  onClick={downloadInvoice}
                >
                  Download Invoice
                </button>
              </div>
            </div>
          </div> */}
          </div>
        )
      ) : flag !== 100 ? (
        showPaymentSuccess && (
          <div className="max-w-full justify-center items-center flex flex-col my-6 ">
            <div className="text-center mt-14">
              <h1>Payment Successful</h1>
              <p>Booking done Successfully!</p>
            </div>
            <div className="relative bg-white mt-10 p-6 rounded-md border border-gray-300 max-w-md mx-auto">
              <div className="text-center">
                <div className="flex flex-col items-center justify-center">
                  <div className="rounded-full bg-[#F6E0E1] flex justify-center items-center w-24 h-24">
                    <img
                      src={Success}
                      width={90}
                      height={20}
                      className=""
                      alt="Success"
                    />
                  </div>
                  <h3 className="mt-4"> Done!</h3>
                  <span className="mt-2 mb-8">Booking done Successfully! </span>
                  <button
                    type="button"
                    className="px-4 py-2 text-sm font-semibold text-white download-invoice"
                    onClick={downloadInvoice}
                  >
                    Download Invoice
                  </button>
                </div>
              </div>
            </div>
          </div>
        )
      ) : (
        showPaymentFailed && (
          <div className="max-w-full justify-center items-center flex flex-col my-6 ">
            <div className="text-center mt-14">
              <h1>Payment Falied</h1>

              {console.log("Failed")}
              {/* <p>Booking done Successfully!</p> */}
            </div>
            {/* <div className="relative bg-white mt-10 p-6 rounded-md border border-gray-300 max-w-md mx-auto">
              <div className="text-center">
                <div className="flex flex-col items-center justify-center">
                  <div className="rounded-full bg-[#F6E0E1] flex justify-center items-center w-24 h-24">
                    <img
                      src={Success}
                      width={90}
                      height={20}
                      className=""
                      alt="Success"
                    />
                  </div>
                  <h3 className="mt-4"> Done!</h3>
                  <span className="mt-2 mb-8">Booking done Successfully! </span>
                  <button
                    type="button"
                    className="px-4 py-2 text-sm font-semibold text-white download-invoice"
                    onClick={downloadInvoice}
                  >
                    Download Invoice
                  </button>
                </div>
              </div>
            </div> */}
          </div>
        )
      )}
    </>
  );
};

export default SuccessfullPage;
