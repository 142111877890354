import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  updateCardDetailsApi,
  getUserDetailsApi,
  deleteCardApi,
} from "../../handlers/myaccount/api";
import { toast } from "react-toastify";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import VisaLogo from "../../assets/images/visa-logo.png";
import MastercardLogo from "../../assets/images/master-card.png";

function CardAddingPage() {
  const [cards, setCards] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newCardNumber, setNewCardNumber] = useState("");
  const [isPreferred, setIsPreferred] = useState(false);
  const [selectedCardIndex, setSelectedCardIndex] = useState(null);

  const token = localStorage.getItem("token");

  useEffect(() => {
    const token = localStorage.getItem("token");

    getUserDetailsApi(token)
      .then((savedCards) => {
        console.log(savedCards, "saved cards")
        setCards(savedCards);
      })
      .catch((error) => {
        console.error("Error fetching card details:", error);
      });
  }, []);

  const handleAddCard = () => {
    // Check if the new card number is empty or not 16 digits
    if (
      !newCardNumber ||
      newCardNumber.length !== 16 ||
      !/^\d+$/.test(newCardNumber)
    ) {
      toast.error("Error: Please enter a valid 16-digit card number.");
      return;
    }

    // Check if the new card number already exists in the list
    const isDuplicateCard = cards.some(
      (card) => card.card_number === newCardNumber
    );

    if (isDuplicateCard) {
      toast.error("Error: This card number already exists in the list.");
      return;
    }

    // Proceed with adding the new card
    const newCard = {
      card_number: newCardNumber,
      card_first_6_digit: newCardNumber.slice(0, 6),
      preferred_payment_method: isPreferred ? 1 : 0,
    };
    setCards((prevCards) => {
      const updatedCards = [...prevCards];
      if (isPreferred || updatedCards.length === 0) {
        updatedCards.forEach((card) => {
          card.preferred_payment_method = 0; // Deselect other cards
        });
        setIsPreferred(true);
      }
      return [...updatedCards, newCard];
    });

    setIsModalOpen(false);
  };

  function getCardType(firstSixDigits) {
    // Convert to string to handle number input
    const firstSix = firstSixDigits.toString();
    const firstTwo = parseInt(firstSix.substring(0, 2), 10);
    const firstFour = parseInt(firstSix.substring(0, 4), 10);
    const firstOne = parseInt(firstSix.substring(0, 1), 10);

    // Check for Visa
    if (firstOne === 4) {
      return 'Visa';
    }
    // Check for MasterCard
    if ((firstTwo >= 51 && firstTwo <= 55) || (firstFour >= 2221 && firstFour <= 2720)) {
      return 'MasterCard';
    }
    return 'Unknown Card Type';
  }





  function PaymentMethod(firstSixDigits) {
    console.log(firstSixDigits, "card number")
    const cardType = getCardType(firstSixDigits);
    switch (cardType) {
      case 'Visa':
        return VisaLogo;
        break;
      case 'MasterCard':
        return MastercardLogo;
        break;
      default:
        return CreditCardIcon;
        break;
    }
  }

  const handleCardSelect = (index) => {
    const updatedCards = [...cards];
    updatedCards.forEach((card, i) => {
      card.selected = i === index;
      if (i === index) {
        card.preferred_payment_method = 1;
      } else {
        card.preferred_payment_method = 0;
      }
    });
    deleteCardApi(updatedCards, token)
      .then((response) => {
        console.log(response, "response");

        setCards(updatedCards);
      })
      .catch((error) => {
        console.error("Error deleting card:", error);
      });

    setCards(updatedCards);
  };

  const handleDeleteCard = (index) => {
    const deletedCard = cards[index];
    const updatedCards = cards.filter((card, i) => i !== index);


    if (deletedCard.preferred_payment_method === 1 && updatedCards.length > 0) {
      updatedCards[0].preferred_payment_method = 1;
    }

    console.log(updatedCards,"updated ")

    deleteCardApi(updatedCards, token)
      .then((response) => {
        console.log(response, "response");
        toast.success("Card deleted successfully:", response);
        setCards(updatedCards);
      })
      .catch((error) => {
        console.error("Error deleting card:", error);
      });
  };

  const handleSubmit = () => {
    const formattedData = {
      card_details: cards.map((card) => ({
        card_number: card.card_number,
        card_first_6_digit: card.card_first_6_digit,
        preferred_payment_method: card.preferred_payment_method,
        expiry_date:card.expiry_date
      })),
    };

    updateCardDetailsApi(formattedData, token)
      .then((response) => {
        toast.success("Payment Preference Added Successfully", response);
      })
      .catch((error) => {
        console.error("Error submitting data:", error);
      });
  };

  const formatCardNumber = (cardNumber) => {
    // Assuming the original format is "4545454545454545"
    // Change this logic based on your original card number format
    const formattedNumber = cardNumber.match(/.{1,4}/g).join("-");
    return formattedNumber;
  };

  return (
    <div className=" bg-white rounded-lg overflow-hidden  lg:px-6">
      {cards.length === 0 ? (
        <div
          className={`border flex border-gray-300 p-4 rounded-lg justify-center`}
        >
          <div className="text-lg font-semibold">
            Cards used for a payment will appear here...
          </div>
        </div>
      ) : (
        <div className="grid grid-cols-1 gap-4">
          {cards.map((card, index) => (
            <div
              key={index}
              className={`border flex border-gray-300 p-4 rounded-lg ${index === selectedCardIndex ? "border-2 border-blue-500" : ""
                }`}
            >
              <div className="mb-2 mr-5">
                <img
                  src={PaymentMethod(card.card_first_6_digit)}
                  style={{
                    height: "40px",
                    width: "80px",
                    border: "1px solid #f0f0f0",
                  }}
                />
              </div>
              <div>
                <div className="text-lg font-semibold">
                  Card Ending in: {card.card_last_4_digit}
                </div>
                <div className="text-md font">
                  Expiry Date: {`${card.expiry_date.toString().slice(0, 2)}/${card.expiry_date.toString().slice(2, 6)}`}
                </div>



                <div className="flex items-center">
                  <label
                    className="flex items-center mr-4 cursor-pointer"
                    onClick={() => {
                      handleCardSelect(index);
                      setIsPreferred(true);
                    }}
                  >
                    <input
                      type="radio"
                      checked={card.preferred_payment_method === 1}
                      onChange={() => {
                        handleCardSelect(index);
                        setIsPreferred(true);
                      }}
                      className="mr-2"
                    />
                    {card.preferred_payment_method === 1
                      ? "Default"
                      : "Set as default"}
                  </label>
                  <button
                    onClick={() => handleDeleteCard(index)}
                    className="text-red-500 font-semibold py-2 px-4 rounded-lg"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      {isModalOpen && (
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen p-4">
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <div className="relative bg-white rounded-lg overflow-hidden shadow-xl max-w-md w-full p-6">
              <button
                onClick={() => setIsModalOpen(false)}
                className="absolute top-0 right-0 p-2 text-gray-600 hover:text-gray-800"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
              <h2 className="text-xl font-semibold mb-4">Add Card</h2>
              <input
                type="text"
                placeholder="Card Number"
                value={newCardNumber}
                onChange={(e) => {
                  // Limit input to 16 characters
                  if (e.target.value.length <= 16) {
                    setNewCardNumber(e.target.value);
                  }
                }}
                className="w-full border rounded-lg px-3 py-2 mb-4"
              />

              <label className="flex items-center mb-4">
                <input
                  type="checkbox"
                  checked={isPreferred}
                  onChange={(e) => setIsPreferred(e.target.checked)}
                  className="mr-2"
                />
                Preferred Payment Method
              </label>
              <button
                onClick={handleAddCard}
                className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg"
              >
                Add Card
              </button>
              <button
                onClick={() => setIsModalOpen(false)}
                className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-lg ml-2"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      {/* {cards.length > 0 && (
        <button
          onClick={() => setIsModalOpen(true)}
          className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded-lg mt-4 "
        >
          Add Another Card
        </button>
      )}
      {cards.length > 0 && (
        <button
          onClick={handleSubmit}
          disabled={!isPreferred}
          className={`bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg mt-4 ml-4  ${
            !isPreferred ? " cursor-not-allowed" : ""
          }`}
        >
          Save
        </button>
      )} */}
    </div>
  );
}

export default CardAddingPage;
