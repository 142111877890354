import React from "react";
// import Logo from "../../assets/images/company_logo_cricket.png";

function Navbar({ isLoggedIn }) {
  const Logo = require("../../assets/images/company_logo_cricket.png");
  return (
    <nav className="bg-gray-300  max-w-full px-20  flex justify-end items-center"> 
      <img src={Logo} alt="Logo" className="h-12" />
    </nav>
  );
}

export default Navbar;
