import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const GoogleCallback = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // This would typically involve checking for tokens in the URL or response
    // For demonstration, we'll just navigate back to the home page
    navigate("/");
  }, [navigate]);

  return <div>Loading...</div>;
};

export default GoogleCallback;
