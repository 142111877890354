import { Modal, Input, Button } from 'antd';
import { useState } from 'react';
import { sendOTP as sendOTPAPI, verifyOTP as verifyOTPAPI } from "../../handlers/authentication/api";
import { toast } from 'react-toastify';
import {
    verifySignupOTPApi,
    setNewMobileApi,
} from "../../handlers/myaccount/api";
import axios from "axios";

import {
    SetNewPhoneNumber,
} from "../../handlers/common/api";

const PhoneNumberOTPModal = ({ isModalVisible, setIsModalVisible }) => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [otp, setOtp] = useState('');
    const [otpSent, setOtpSent] = useState(false);
    const [countryCode, setCountryCode] = useState('+65');
    const [loading, setLoading] = useState(false);
    const [isVerified, setIsVerified] = useState(false);

    const sendOTP = async () => {
        if (phoneNumber.length == 8) {
            setLoading(true);
            try {
                await sendOTPAPI(`${countryCode}${phoneNumber}`);
                setOtpSent(true);
                toast.success("OTP Successfully Sent to your Registered Phone Number");
            } catch (error) {
                console.error("Failed to send OTP:", error);
                toast.error("Failed to send OTP. Please try again.");
            } finally {
                setLoading(false);
            }
        } else {
            toast.warning("Please enter a phone number with  8 digits.");
        }
    };

    const verifyOTP = async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem("token");
            const otpVerificationResponse = await verifySignupOTPApi(otp, `${countryCode}${phoneNumber}`, token);
            console.log(otpVerificationResponse, "verification response")

            if (otpVerificationResponse) {
                // toast.success("OTP Verified Successfully");
                setIsVerified(true);
                handleSubmit()
            } else {
                toast.error("Failed to verify OTP");
                console.error("Failed to verify OTP");
            }
        } catch (error) {
            toast.error("An error occurred during OTP verification");
            console.error(error.message);
        } finally {
            setLoading(false);
        }
    };



    const handleSubmit = async () => {
        try {
            const token = localStorage.getItem("token");

            const response = await axios.post(
                SetNewPhoneNumber,
                {
                    phone: `${countryCode}${phoneNumber}`,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Basic ${token}`,
                    },
                }
            );
            console.log(response.data.developer_message.message, "set phone number response");

            if (response.data.developer_message.message == "This Mobile no used another User please enter diffrent mobile no.") {
                toast.success("Mobile Number already used with another user. Try Different Number");
                setOtpSent(false);
                setIsVerified(false);
            } else if (response.data.developer_message.message == "Mobile Updated Successfully.") {
                toast.success("Mobile Number verified and updated successfully");
                setIsModalVisible(false);
                // console.error("Failed to update phone number after OTP verification");
            } else {
                toast.error("Failed to update phone number");
                console.error("Failed to update phone number after OTP verification");
            }

        } catch (error) {
            toast.error("Failed to update phone number");
            setOtpSent(false)
            setIsVerified(false);
        }
    }

    const handleChange = (e) => {
        setCountryCode(e.target.value);
    };

    return (
        <Modal
            title="Verify Phone Number"
            visible={isModalVisible}
            footer={null}
            closable={false}
            className="removeCartPopup"
            centered
            style={{ maxWidth: '100%', width: '90%', top: '20px' }}
        >
            <p>Enter your phone number to receive an OTP.</p>

            <div className="flex items-center space-x-2 mb-4">
                <select
                    id="countrycode"
                    name="countrycode"
                    value={countryCode}
                    onChange={handleChange}
                    className="px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    disabled={otpSent}
                >
                    <option value="+65">+65</option>
                    {/* <option value="+91">+91</option> */}
                </select>

                <Input
                    placeholder="Enter Phone Number"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    style={{ marginBottom: '0' }}
                    disabled={otpSent}
                    maxLength={8}
                />
            </div>

            {otpSent && (
                <Input
                    placeholder="Enter OTP"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    style={{ marginBottom: '1rem' }}
                    disabled={isVerified}
                />
            )}

            {!otpSent ? (
                <Button
                    type="primary"
                    className="text-white"
                    onClick={sendOTP}
                    disabled={!phoneNumber || loading}
                    loading={loading}
                >
                    Send OTP
                </Button>
            ) : (
                <Button
                    type="primary"
                    className="text-white"
                    onClick={verifyOTP}
                    disabled={!otp || isVerified || loading}
                    loading={loading}
                >
                    Verify OTP
                </Button>
            )}
        </Modal>
    );
};

export default PhoneNumberOTPModal;
