import axios from "axios";
import {
  AlreadyBookedPitch,
  CreateOrder,
  Holidays,
  ItemDetails,
  venueListingPage,
  CouponCode,
  GetCountryTime,
} from "../common/api";

export const handleCreateOrder = async (payload, token) => {
  try {
    const response = await axios.post(
      CreateOrder,
      {
        cart_data: payload,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${token}`,
        },
      }
    );

    if (response.status === 200) {
      return response;
    } else {
      throw new Error("Failed to create order");
    }
  } catch (error) {
    throw new Error("Error creating order:", error);
  }
};

export const handleGetProductDetails = async (payload) => {
  try {
    const response = await axios.post(ItemDetails, payload);

    if (response.status === 200) {
      return response;
    } else {
      throw new Error("Failed to get product details");
    }
  } catch (error) {
    throw new Error("Error getiing product details", error);
  }
};

export const getAlreaduBookedPitches = async (payload) => {
  try {
    const response = await axios.post(AlreadyBookedPitch, payload);

    if (response.status === 200) {
      return response;
    } else {
      throw new Error("Failed to get product details");
    }
  } catch (error) {
    throw new Error("Error getiing product details", error);
  }
};

export const getCountrytime = async (payload) => {
  try {
    const response = await axios.post(GetCountryTime, payload);

    if (response.status === 200) {
      return response;
    } else {
      throw new Error("Failed to get product details");
    }
  } catch (error) {
    throw new Error("Error getiing product details", error);
  }
};

export const getHolidaylist = async (payload) => {
  try {
    const response = await axios.post(Holidays, payload);

    if (response.status === 200) {
      return response;
    } else {
      throw new Error("Failed to get product details");
    }
  } catch (error) {
    throw new Error("Error getiing product details", error);
  }
};

export const getPitchData = async (payload) => {
  try {
    const response = await axios.get(venueListingPage + "/" + payload);

    if (response.status === 200) {
      return response;
    } else {
      throw new Error("Failed to get product details");
    }
  } catch (error) {
    throw new Error("Error getiing product details", error);
  }
};

export const validateCouponCode = async (Coupon, Amount) => {
  try {
    const response = await axios.get(CouponCode, {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        amount: Amount,
        coupon_name: Coupon,
      },
    });

    if (response.status === 200) {
      return response;
    } else {
      throw new Error("Failed to apply coupon");
    }
  } catch (error) {
    throw new Error("Error getting discount:", error);
  }
};
