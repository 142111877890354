import React, { useEffect } from "react";
import Logo from "../../assets/images/logo.svg";
import NotificationBell from "../../assets/images/bell.svg";
import profileIcon from "../../assets/images/defaultProfilePic.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useRef } from "react";
import { fetchUserDatasApi } from "../../handlers/myaccount/api";
import { toast } from "react-toastify";
import { Card, Select } from "antd";
import { googleLogout } from "@react-oauth/google";

function Navbar({ toggleSidebar }) {
  const [image, setImage] = useState();
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchActivityOptions, setSearchActivityOptions] = useState([]);
  const [selectedSearchActivity, setSelectedSearchActivity] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const dropdownRef = useRef(null);

  let flag = false;

  const handleMyAccountNavigation = () => {
    const token = localStorage.getItem("token");
    if (token && location.pathname !== "/my-account") {
      navigate(`/my-account`);
    }
  };
  const handleHomeRedirection = () => {
    navigate(`/`);
  };


  function getImageUrl(image, baseUrl) {
    if (image.startsWith('/files')) {
      
      return `${baseUrl}${image}`;
    } else if (image.startsWith('https://')) {
     
      return image;
    } else if (image.startsWith('/static')) {
      
      return image;
    } else {
      
      return image; 
    }
  }

  const fetchUserData = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const userData = await fetchUserDatasApi(token);

        console.log("userData",userData)
        if (userData.email === "") {
          if (token) {
            toast.error("Your Session is expired please login again");
          }
          localStorage.removeItem("token");
        }

        const imageUrl = getImageUrl(userData.image, userData.baseurl);
        console.log(imageUrl,"user image");


        setImage(imageUrl);
      } catch (error) {
        console.error(error.message);
        return false;
      }
    }
  };

  const handleSignUp = () => {
    navigate("/sign-up");
  };
  const handleViewCart = () => {
    navigate("/viewcart");
  };
  const handleLogin = () => {
    navigate("/sign-in");
  };
  const handleNotifications = () => {
    navigate("/my-account");
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("cartItems");
    googleLogout();
    // Redirect to home page or any other page after logout
    navigate("/");
  };

  const handleClickOutside = (event) => {
    // Function to handle clicks outside the dropdown
    // Check if the click is outside the dropdown element
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false); // Hide the dropdown if the click is outside
    }
  };

  useEffect(() => {
    // Effect to add/remove the event listener
    if (showDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("touchstart", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, [showDropdown]);

  useEffect(() => {
    if (flag === false) {
      fetchUserData();
      flag = true;
    }
  }, []);

  return (
    <nav className="bg-[#f2f2f2] w-full py-4 flex justify-between items-center  navbar  ">
      {/* Left side icons */}
      <div className="flex items-center">
        {localStorage.getItem("token") && (
          <>
            <div
              className="flex nav-toggle"
              onClick={handleMyAccountNavigation}
            >
              <FontAwesomeIcon
                icon={faBars}
                size="lg"
                className="toggle-bars"
                onClick={toggleSidebar}
              />
              {/* Using AccountCircleIcon directly as a component */}
              {image ? (
                <img
                  className="rounded-full nav-profile-pic"
                  src={image}
                  alt="ProfileImage"
                />
              ) : (
                <img
                  src={profileIcon}
                  alt="ProfileImage"
                  onClick={handleHomeRedirection}
                  className="rounded-full nav-profile-pic"
                />
              )}
            </div>
            {/* <img
              src={NotificationBell}
              alt="Notification"
              onClick={handleNotifications}
              className="h-12 notification"
            /> */}
          </>
        )}
      </div>

      <div className="flex items-center flex-row-reverse gap-2 rightLinks">
        {/* Mobile Nav */}
        <div className="sm:hidden relative">
          <button
            onClick={() => setShowDropdown(!showDropdown)}
            className="text-xl font-black focus:outline-none"
          >
            &#8942;
          </button>
          {showDropdown && (
            <div
              ref={dropdownRef}
              className="absolute z-10 right-0 top-12 bg-red-200 mt-2 rounded-md shadow-lg p-2 w-48"
            >
              {!localStorage.getItem("token") && (
                <>
                  <span
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-200 cursor-pointer"
                    onClick={handleLogin}
                  >
                    Log In
                  </span>
                  <span
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-200 cursor-pointer"
                    onClick={handleSignUp}
                  >
                    Sign Up
                  </span>
                </>
              )}
              {localStorage.getItem("token") && (
                <span
                  className="block px-4 py-2 text-gray-800 hover:bg-gray-200 cursor-pointer"
                  onClick={handleLogout}
                >
                  Logout
                </span>
              )}
              <span
                className="block px-4 py-2 text-gray-800 hover:bg-gray-200 cursor-pointer"
                onClick={handleViewCart}
              >
                View Cart
              </span>
            </div>
          )}
        </div>
        <div className="flex nav-links ">
          {!localStorage.getItem("token") && (
            <>
              <span
                className="mr-4 font-bold hidden sm:block"
                onClick={handleLogin}
              >
                Log In
              </span>
              <span
                className="mr-4 font-bold hidden sm:block"
                onClick={handleSignUp}
              >
                Sign Up
              </span>
            </>
          )}
          {localStorage.getItem("token") && (
            <span
              className="mr-4 font-bold hidden sm:block"
              onClick={handleLogout}
            >
              Logout
            </span>
          )}
          <span
            className="mr-4 font-bold hidden sm:block"
            onClick={handleViewCart}
          >
            View Cart
          </span>
        </div>
        <img
          src={Logo}
          alt="Logo"
          onClick={handleHomeRedirection}
          className="h-12 nav-logo"
        />
      </div>
    </nav>
  );
}

export default Navbar;
