import React, { useState, useEffect } from "react";
import axios from "axios";
import Navbar from "../../component/common/Navbar";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

const VerificationPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const [full_name, setFullName] = useState("");
  const [apiResponse, setApiResponse] = useState("");
  const [error, setError] = useState("");
  const [verified,setVerified] =useState(false)


  useEffect(() => {
    const fetchData = async () => {
      try {
        const pathname = window.location.pathname;

        const parts = pathname.split("/");
        const emailParam = parts[3];
        const tokenParam = parts[5];
        let fullNameParam = parts[7];

        fullNameParam = fullNameParam.replace(/_/g, " ");

        setEmail(emailParam || "");
        setToken(tokenParam || "");
        setFullName(fullNameParam || "");
      } catch (error) {
        setError("Error: Failed to fetch data");
      }
    };

    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const pathname = window.location.pathname;

        const parts = pathname.split("/");
        const emailParam = parts[3];
        const tokenParam = parts[5];
        let fullNameParam = parts[7];

        fullNameParam = fullNameParam.replace(/_/g, " ");

        console.log(tokenParam)


      const response = await axios.post(
        "https://devmindmedia.frappe.cloud/api/method/mindmedia.api.api.verify_email_verification_token",
        {
          email: emailParam,
          token: tokenParam || "",
        }
      );


      console.log(response,"response sample")

      if (response.data.message.status_code === 200) {
        toast.success("Verification successful!");
        setVerified(true)
        navigate('/sign-up', { state: { formData: { full_name, email } } });
      } else {
        throw new Error("Verification failed.");
      }
      setApiResponse(response.data);
    } catch (error) {
      setError("Failed to send Verification request. Please try again.");
      toast.warning("Error: " + error.message);
    }
  };



  return (
    <>
      <Navbar />
      <div className="flex justify-center items-center min-h-screen">
        <div className="max-w-md w-full bg-gray-200 rounded-lg shadow-md p-6 md:p-10">
          <h2 className="text-2xl text-center font-semibold mb-4">
            Verification
          </h2>
          <div className="mt-4">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email
            </label>
            <input
              id="email"
              name="email"
              type="email"
              value={email}
              readOnly
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <p className="mt-2 text-center">
            Please take a moment to validate your email, <br /> to complete the
            registration process.
          </p>
          {error && <p className="text-red-500 mt-2">{error}</p>}
          <button
            onClick={handleSubmit}
            className="w-full mt-4 inline-flex items-center justify-center rounded-md bg-black text-white px-3.5 py-2.5 font-semibold leading-7 hover:bg-black/80"
          >
            Continue
          </button>
        </div>
      </div>
    </>
  );
};

export default VerificationPage;
