import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { fetchUserDataApi } from "../../handlers/myaccount/api";

const CardSelectionPopup = ({ isOpen, onRequestClose, onSelect }) => {
  const [selectedCard, setSelectedCard] = useState(null);
  const [cardData, setCardData] = useState([]);

  const handleCardSelect = (card) => {
    console.log(card);
    setSelectedCard(card);
  };

  const handleProceed = () => {
    if (selectedCard) {
      onSelect(selectedCard);
      onRequestClose();
    }
  };

  const getdata = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      const res = await fetchUserDataApi(token);
      const cards = res.data.saved_cards;
      setCardData(cards);
      const preferredCard = cards.find(
        (card) => card.preferred_payment_method === 1
      );
      if (preferredCard) {
        setSelectedCard(preferredCard);
      }
      // setCardData(res.data.saved_cards);
    }
  };

  useEffect(() => {
    getdata();
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={true}
      contentLabel="Select Card"
      className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75"
      overlayClassName="fixed inset-0 bg-gray-800 bg-opacity-75"
      ariaHideApp={false}
    >
      <div className="bg-white p-6 rounded-lg shadow-lg w-3/4 max-w-2xl relative max-h-[80vh] overflow-y-auto">
        <button
          onClick={onRequestClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-800 text-3xl"
        >
          &times;
        </button>
        <h2 className="text-2xl font-bold mb-4">Select a Card</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {cardData.map((card, index) => (
            <div
              key={index}
              className={`border p-4 rounded-lg flex flex-col md:flex-row items-center justify-between cursor-pointer transition transform hover:scale-105 ${selectedCard === card
                  ? "border-blue-500 bg-blue-100"
                  : "border-gray-300"
                }`}
              onClick={() => handleCardSelect(card)}
            >
              <div className="text-center md:text-left mb-2 md:mb-0 mr-2">
                <div className="text-lg font-semibold">
                  Card Ending in: {card.card_last_4_digit}
                </div>
                <div className="text-gray-600">{card.card_type}</div>
              </div>
              <button
                className={`py-2 px-4 rounded-lg font-semibold ${selectedCard === card
                    ? "bg-blue-500 text-white"
                    : "bg-gray-300 text-gray-700"
                  }`}
              >
                {selectedCard === card ? "Selected" : "Select"}
              </button>
            </div>
          ))}
          <div
            className={`border p-4 rounded-lg flex flex-col md:flex-row items-center justify-between cursor-pointer transition transform hover:scale-105 ${selectedCard === "Add New Card"
                ? "border-blue-500 bg-blue-100"
                : "border-gray-300"
              }`}
            onClick={() => handleCardSelect("Add New Card")}
          >
            <div className="text-center md:text-left mb-2 md:mb-0">
              <div className="text-lg font-semibold">Add New Card</div>
            </div>
            <button
              className={`py-2 px-4 rounded-lg font-semibold ${selectedCard === "Add New Card"
                  ? "bg-blue-500 text-white"
                  : "bg-gray-300 text-gray-700"
                }`}
            >
              {selectedCard === "Add New Card" ? "Selected" : "Select"}
            </button>
          </div>
        </div>
        <button
          onClick={handleProceed}
          disabled={!selectedCard}
          className="mt-4 w-full bg-blue-500 text-white py-2 px-4 rounded disabled:bg-gray-300"
        >
          Proceed
        </button>
      </div>
    </Modal>
  );
};

export default CardSelectionPopup;
