import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Routes from "./routes/index";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { getClientid } from "./handlers/common/api";
import { getClientId } from "./handlers/authentication/api";

const App = () => {
  const [clientId, setClientId] = useState(null);

  useEffect(() => {
    const fetchClientId = async () => {
      try {
        const response = await getClientId();
        setClientId(response.data.data.client_id);
      } catch (error) {
        console.error("Error fetching clientId:", error);
      }
    };

    fetchClientId();
  }, []);

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <Routes />
    </GoogleOAuthProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
