import axios from "axios";
import {
  Invoice,
  RequestPayment,
  Tax,
  checkIfPaymentAlreadyDone,
  create_si_and_payment_api,
  create_si_and_payment_api_for_iframe,
  submit_si_pmt_entry_store_payerid_touser,
  handlePayment,
  get_si_from_trans,
  setPaymentUrlOnSoApiUrl,
} from "../common/api";
import { useParams } from "react-router-dom";

export const requestPayment = async (payload) => {
  console.log(payload);
  try {
    const response = await axios.post(RequestPayment, payload);

    if (response.status === 200) {
      localStorage.setItem("submit_payment", true);
      return response;
    } else {
      throw new Error("Failed to request payment");
    }
  } catch (error) {
    throw new Error("Error getiing request payment", error);
  }
};

export const setPaymentUrlOnSo = async (payload, token) => {
  console.log(payload);
  try {
    const response = await axios.post(setPaymentUrlOnSoApiUrl, payload, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${token}`,
      },
    });

    if (response.status === 200) {
      return response;
    } else {
      throw new Error("Failed to set payment url in so");
    }
  } catch (error) {
    throw new Error("Error setting payment url on so", error);
  }
};

export const handleRedirect = async (payload) => {
  console.log(payload, "Payload");
  try {
    const response = await axios.post(handlePayment, payload);

    if (response.status === 200) {
      return response;
    } else {
      throw new Error("Failed to redirect payment");
    }
  } catch (error) {
    throw new Error("Error getiing redirect", error);
  }
};

export const Create_si_and_payment_api = async (payload, token) => {
  console.log(payload, "Payload");
  try {
    const response = await axios.post(create_si_and_payment_api, payload, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${token}`,
      },
    });

    if (response.status === 200) {
      return response;
    } else {
      throw new Error("Failed to redirect payment");
    }
  } catch (error) {
    throw new Error("Error getiing redirect", error);
  }
};

export const Submit_si_pmt_entry_store_payerid_touser = async (
  payload,
  token
) => {
  // console.log("payload",payload);
  let payment_indicator = localStorage.getItem("submit_payment");
  // console.log("ind",payment_indicator);
  try {
    if (payment_indicator) {
      const response = await axios.post(
        submit_si_pmt_entry_store_payerid_touser,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        return response;
      } else {
        throw new Error("Failed to redirect payment Entry Store");
      }
    }
  } catch (error) {
    throw new Error("Error getiing redirect", error);
  }
};

export const Create_si_and_payment_api_for_iframe = async (payload) => {
  console.log(payload, "Payload");
  try {
    const response = await axios.post(
      create_si_and_payment_api_for_iframe,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {
      return response;
    } else {
      throw new Error("Failed to redirect payment");
    }
  } catch (error) {
    throw new Error("Error getiing redirect", error);
  }
};

export const downloadInvoiceHelper = async (payload, token) => {
  console.log(Invoice.concat(`&name=${payload}`), payload, "Payload");
  try {
    const response = await axios.get(Invoice.concat(`&name=${payload}`), {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${token}`,
      },
    });

    if (response.status === 200) {
      return response;
    } else {
      throw new Error("Failed to download Invoice");
    }
  } catch (error) {
    throw new Error("Error downloading Invoice", error);
  }
};

export const fetchTaxApi = async (payload) => {
  try {
    const response = await axios.post(
      Tax,
      { booking_for: payload },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {
      return response;
    } else {
      throw new Error("Failed to Load Tax");
    }
  } catch (error) {
    throw new Error("Error Loading Tax", error);
  }
};

export const CheckIfPaymentAlreadyDone = async (payload) => {
  console.log(payload, "Payload");
  try {
    const response = await axios.post(
      checkIfPaymentAlreadyDone,
      { order_number: payload },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {
      return response;
    } else {
      throw new Error("Failed to redirect payment");
    }
  } catch (error) {
    throw new Error("Error getiing redirect", error);
  }
};

export const get_si_form_tran = async (payload) => {
  console.log(payload, "Payload");
  try {
    const response = await axios.post(
      get_si_from_trans,
      { trans_number: payload },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {
      return response;
    } else {
      throw new Error("Failed to redirect payment");
    }
  } catch (error) {
    throw new Error("Error getiing redirect", error);
  }
};
