import React from "react";
import { DateTime } from "luxon";

const WeekCalendar = ({
  prevWeek,
  nextWeek,
  startDate,
  formatDateToDayMonth,
  newTimeWithslotsWeek,
  pitchWeekAvailability,
  getDayOfWeek,
  formatTimeRangespace,
  visiblePitches,
  selectedPitchName,
  isSlotAlreadyBooked,
  isSlotSelectedInCart,
  isSlotDeleatable,
  selectedDate,
  removeFromCart,
  handleBoxClick,
  selectedPitchIcon,
  singaporeTime,
}) => {
  function formatDate(date) {
    if (!date) {
      return "";
    }

    if (!(date instanceof Date)) {
      date = new Date(date);
    }

    if (isNaN(date.getTime())) {
      return "";
    }

    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    return `${day} ${months[monthIndex]} ${year}`;
  }

  function convertTo24HourFormat(timeRange) {
    // Split the time range into start and end times
    const [startTime, endTime] = timeRange.split('-');
  
    // Convert each time to 24-hour format
    const startTime24 = to24Hour(startTime);
    const endTime24 = to24Hour(endTime);
  
    // Return the converted time range
    return `${startTime24}-${endTime24}`;
  }
  
  function to24Hour(time) {
    const [timePart, modifier] = time.match(/(\d{1,2}:\d{2})(AM|PM)/i).slice(1, 3);
    let [hours, minutes] = timePart.split(':').map(Number);
  
    if (modifier.toUpperCase() === 'PM' && hours !== 12) {
      hours += 12;
    } else if (modifier.toUpperCase() === 'AM' && hours === 12) {
      hours = 0;
    }
  
    // Format hours to ensure two digits
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  }

  const getSingaporeTimeAsDateObject = () => {
    // Get the current date and time in Singapore as a string
    let singaporeTimeString = new Date().toLocaleString("en-US", {
      timeZone: "Asia/Singapore",
    });

    // Split the date and time parts
    let [datePart, timePart] = singaporeTimeString.split(", ");

    // Further split the date and time into components
    let [month, day, year] = datePart.split("/");
    let [hours, minutes, seconds] = timePart.split(/[: ]/).map(Number);
    let period = timePart.split(" ")[1];

    // Adjust hours for AM/PM
    if (period === "PM" && hours !== 12) {
      hours += 12;
    } else if (period === "AM" && hours === 12) {
      hours = 0;
    }

    // Create a new Date object in the local time zone with the Singapore time components
    let singaporeDate = new Date(year, month - 1, day, hours, minutes, seconds);

    return singaporeDate;
  };

  const formatTimeRangespacesample = (timeRange) => {
    if (timeRange) {
      const insertSpace = (time) => {
        return time.replace(/([AP]M)/, " $1");
      };

      const [start, end] = timeRange.split("-");

      const formattedStart = insertSpace(start);

      const formattedEnd = insertSpace(end);

      return `${formattedStart}-${formattedEnd}`;
    } else {
      return;
    }
  };

  const parseTimeToSingapore = (timeStr, referenceDate) => {
    const [time, modifier] = timeStr.split(" ");
    let [hours, minutes] = time.split(":").map(Number);

    if (modifier === "PM" && hours !== 12) {
      hours += 12;
    } else if (modifier === "AM" && hours === 12) {
      hours = 0;
    }

    return referenceDate.set({ hour: hours, minute: minutes, second: 0 });
  };

  const isSlotDisabledByTodayTime = (
    slotTime,
    pitch,
    isWeekView = false,
    date = null
  ) => {
    if (!singaporeTime) {
      console.error("Singapore time is not set yet");
      return false;
    }

    // Use the fetched Singapore time
    let now = singaporeTime;
    let currentDate = isWeekView
      ? DateTime.fromJSDate(date).setZone("Asia/Singapore")
      : selectedDate
      ? DateTime.fromJSDate(selectedDate).setZone("Asia/Singapore")
      : now;

    // If pitch is provided, add the pitch hours to the current time
    if (pitch) {
      const pitchHours = parseInt(pitch, 10);
      now = now.plus({ hours: pitchHours });
    }

    const [startTime, endTime] = slotTime.split("-").map((time) => time.trim());

    const slotStartTime = parseTimeToSingapore(startTime, currentDate);

    // Compare slotStartTime with the adjusted now time

    return slotStartTime < now;
  };

  function checkIfTimeFitsInRangeoforDay(
    availableRangeStart,
    availableRangeEnd,
    timeToCheck
  ) {
    if (!timeToCheck) {
      return false;
    }
    if (availableRangeEnd.startsWith("23:59")) {
      availableRangeEnd = "24:00:00";
    }

    const convert24HourToMinutes = (time) => {
      let [hours, minutes, seconds] = time.split(":").map(Number);
      return hours * 60 + minutes + seconds / 60;
    };

    const convert12HourToMinutes = (time) => {
      let [timePart, modifier] = time.split(" ");
      let [hours, minutes] = timePart.split(":").map(Number);

      if (modifier === "PM" && hours !== 12) {
        hours += 12;
      } else if (modifier === "AM" && hours === 12) {
        hours = 0;
      }

      return hours * 60 + minutes;
    };

    const availableStart = convert24HourToMinutes(availableRangeStart);
    const availableEnd = convert24HourToMinutes(availableRangeEnd);
    const [startTime, endTime] = timeToCheck.split("-").map((t) => t.trim());
    const startMinutes = convert12HourToMinutes(startTime);
    const endMinutes = convert12HourToMinutes(endTime);

    const adjustedEndMinutes =
      endMinutes < startMinutes ? endMinutes + 24 * 60 : endMinutes;

    const fitsInRange =
      startMinutes >= availableStart && adjustedEndMinutes <= availableEnd;

    return fitsInRange;
  }

  const headingBoxStyle = {
    width: "170px",
    height: "35px",
    border: "1px solid #ccc",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "5px",
  };

  const boxStyleweeks = {
    width: "150px",
    height: "auto",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: "5px",
  };

  return (
    <div className="flex ml-20 items-center mb-12 week-calender">
      <div className="week-internal">
        <div className="mb-4 text-w week-internal-section">
          <div className="flex text-white">
            <div className="flex-col time-col-week">
              <div className="flex justify-end">
                <div
                  className="headingBoxStyle arrow-left"
                  style={{ width: "30px", cursor: "pointer" }}
                  onClick={prevWeek}
                >
                  {"<"}
                </div>
              </div>
            </div>
            <div>
              <div className="flex">
                {[...Array(7)].map((_, index) => (
                  <div
                    key={index}
                    style={{ ...headingBoxStyle, width: boxStyleweeks.width }}
                    className="headingBoxStyle"
                  >
                    {formatDateToDayMonth(
                      new Date(
                        startDate.getFullYear(),
                        startDate.getMonth(),
                        startDate.getDate() + index
                      )
                    )}
                  </div>
                ))}
                <div
                  key={">"}
                  className="headingBoxStyle arrow-right"
                  style={{ width: "30px", cursor: "pointer" }}
                  onClick={nextWeek}
                >
                  {">"}
                </div>
              </div>
              <div>
                {[
                  ...Array(
                    newTimeWithslotsWeek?.reduce((maxLength, current) => {
                      return Math.max(maxLength, current.gaps.length);
                    }, 0)
                  ),
                ].map((_, blockIndex) => (
                  <div key={blockIndex} className="flex  mb-4">
                    {[...Array(7)].map((_, dayIndex) => {
                      const isPitchAvailableday =
                        pitchWeekAvailability[
                          getDayOfWeek(
                            formatDate(
                              new Date(
                                startDate.getFullYear(),
                                startDate.getMonth(),
                                startDate.getDate() + dayIndex
                              )
                            )
                          )
                        ];
                      const formattedDate = new Date(
                        startDate.getFullYear(),
                        startDate.getMonth(),
                        startDate.getDate() + dayIndex + 1
                      )
                        .toISOString()
                        .split("T")[0];

                      const isPitchAvailable =
                        newTimeWithslotsWeek?.[dayIndex]?.gaps?.[blockIndex]
                          ?.type === "gap" ||
                        (isPitchAvailableday &&
                          !isPitchAvailableday.some((slot) => {
                            return checkIfTimeFitsInRangeoforDay(
                              slot.start_time,
                              slot.end_time,
                              formatTimeRangespace(
                                newTimeWithslotsWeek?.[dayIndex]?.gaps?.[
                                  blockIndex
                                ]?.range
                              )
                            );
                          }));
                      const is8A = selectedPitchName?.consecutive_pitch;

                      return (
                        <>
                          {!newTimeWithslotsWeek?.[dayIndex]?.gaps?.[blockIndex]
                            ?.range ? (
                            <div className="boxStyleWeeks empty-div flex-col mb-3 mt-3">
                              No Slots <br></br> Available
                            </div>
                          ) : (
                            <div
                              key={dayIndex}
                              style={boxStyleweeks}
                              className="boxStyleWeeks flex-col mb-3 mt-3"
                            >
                              <div className="block text-sm w-full flex justify-center time-slot">
                                {
                                  convertTo24HourFormat(newTimeWithslotsWeek?.[dayIndex]?.gaps?.[
                                    blockIndex
                                  ]?.range)
                                }
                              </div>

                              {visiblePitches.map((pitch, pitchIndex) => {
                                const alreadyBooked = isSlotAlreadyBooked(
                                  pitch,
                                  formatTimeRangespace(
                                    newTimeWithslotsWeek?.[dayIndex]?.gaps?.[
                                      blockIndex
                                    ]?.range
                                  ),
                                  true,
                                  new Date(
                                    startDate.getFullYear(),
                                    startDate.getMonth(),
                                    startDate.getDate() + dayIndex
                                  )
                                );

                                const isDisabledByTime =
                                  isSlotDisabledByTodayTime(
                                    formatTimeRangespace(
                                      newTimeWithslotsWeek?.[dayIndex]?.gaps?.[
                                        blockIndex
                                      ]?.range
                                    ),
                                    pitch.booking_allowed_after_hrs,
                                    true,
                                    new Date(
                                      startDate.getFullYear(),
                                      startDate.getMonth(),
                                      startDate.getDate() + dayIndex
                                    )
                                  );

                                return (
                                  <div
                                    key={pitchIndex}
                                    className={`pitch-week-box-style mb-2`}
                                    style={{
                                      backgroundColor: isSlotSelectedInCart(
                                        pitch,
                                        formatTimeRangespace(
                                          newTimeWithslotsWeek?.[dayIndex]
                                            ?.gaps?.[blockIndex]?.range
                                        ),
                                        true,
                                        new Date(
                                          startDate.getFullYear(),
                                          startDate.getMonth(),
                                          startDate.getDate() + dayIndex
                                        )
                                      )
                                        ? "green"
                                        : alreadyBooked ||
                                          isPitchAvailable ||
                                          isDisabledByTime
                                        ? "lightgrey"
                                        : "white",

                                      cursor: isSlotDeleatable(
                                        pitch,
                                        formatTimeRangespace(
                                          newTimeWithslotsWeek?.[dayIndex]
                                            ?.gaps?.[blockIndex]?.range
                                        ),
                                        true,
                                        new Date(
                                          startDate.getFullYear(),
                                          startDate.getMonth(),
                                          startDate.getDate() + dayIndex
                                        )
                                      )
                                        ? "pointer"
                                        : isSlotSelectedInCart(
                                            pitch,
                                            formatTimeRangespace(
                                              newTimeWithslotsWeek?.[dayIndex]
                                                ?.gaps?.[blockIndex]?.range
                                            ),
                                            true,
                                            new Date(
                                              startDate.getFullYear(),
                                              startDate.getMonth(),
                                              startDate.getDate() + dayIndex
                                            )
                                          ) ||
                                          (is8A && pitchIndex % 3 !== 0)
                                        ? "not-allowed"
                                        : "pointer",
                                      border: isSlotSelectedInCart(
                                        pitch,
                                        formatTimeRangespace(
                                          newTimeWithslotsWeek?.[dayIndex]
                                            ?.gaps?.[blockIndex]?.range
                                        ),
                                        true,
                                        new Date(
                                          startDate.getFullYear(),
                                          startDate.getMonth(),
                                          startDate.getDate() + dayIndex
                                        )
                                      )
                                        ? "1px solid green"
                                        : alreadyBooked ||
                                          isPitchAvailable ||
                                          isDisabledByTime
                                        ? "1px solid #8F8F8F"
                                        : "",
                                      width: "auto",
                                    }}
                                  >
                                    {/* Checkbox */}
                                    <div
                                      className="sm:text-small flex"
                                      onClick={() => {
                                        if (is8A && pitchIndex % 3 !== 0) {
                                        } else if (
                                          !(
                                            alreadyBooked ||
                                            isPitchAvailable ||
                                            isDisabledByTime
                                          )
                                        ) {
                                          isSlotSelectedInCart(
                                            pitch,
                                            formatTimeRangespace(
                                              newTimeWithslotsWeek?.[dayIndex]
                                                ?.gaps?.[blockIndex]?.range
                                            ),
                                            true,
                                            new Date(
                                              startDate.getFullYear(),
                                              startDate.getMonth(),
                                              startDate.getDate() + dayIndex
                                            )
                                          ) &&
                                          isSlotDeleatable(
                                            pitch,
                                            formatTimeRangespace(
                                              newTimeWithslotsWeek?.[dayIndex]
                                                ?.gaps?.[blockIndex]?.range
                                            ),
                                            true,
                                            new Date(
                                              startDate.getFullYear(),
                                              startDate.getMonth(),
                                              startDate.getDate() + dayIndex
                                            )
                                          )
                                            ? removeFromCart(
                                                pitch.name,
                                                formatTimeRangespace(
                                                  newTimeWithslotsWeek?.[
                                                    dayIndex
                                                  ]?.gaps?.[blockIndex]?.range
                                                ),
                                                formatDate(
                                                  new Date(
                                                    startDate.getFullYear(),
                                                    startDate.getMonth(),
                                                    startDate.getDate() +
                                                      dayIndex
                                                  )
                                                )
                                              )
                                            : console.log(pitch, "boxclick");
                                          handleBoxClick(
                                            pitch,
                                            newTimeWithslotsWeek?.[dayIndex]
                                              ?.gaps?.[blockIndex]?.range,
                                            true,
                                            new Date(
                                              startDate.getFullYear(),
                                              startDate.getMonth(),
                                              startDate.getDate() + dayIndex
                                            )
                                          );

                                          // handleBoxClick(pitch, timeRange,false,selectedDate);
                                        }
                                      }}
                                    >
                                      <div
                                        className="pitch-name-week"
                                        style={{
                                          color: isSlotSelectedInCart(
                                            pitch,
                                            formatTimeRangespace(
                                              newTimeWithslotsWeek?.[dayIndex]
                                                ?.gaps?.[blockIndex]?.range
                                            ),
                                            true,
                                            new Date(
                                              startDate.getFullYear(),
                                              startDate.getMonth(),
                                              startDate.getDate() + dayIndex
                                            )
                                          )
                                            ? "white"
                                            : alreadyBooked ||
                                              isPitchAvailable ||
                                              isDisabledByTime
                                            ? "#8F8F8F"
                                            : "green",
                                        }}
                                      >
                                        {pitch.Pitch_name}
                                      </div>
                                      <img
                                        src={selectedPitchIcon}
                                        alt="Pitch icon"
                                        className="w-4 h-4"
                                        style={{
                                          filter: isSlotSelectedInCart(
                                            pitch,
                                            formatTimeRangespace(
                                              newTimeWithslotsWeek?.[dayIndex]
                                                ?.gaps?.[blockIndex]?.range
                                            ),
                                            true,
                                            new Date(
                                              startDate.getFullYear(),
                                              startDate.getMonth(),
                                              startDate.getDate() + dayIndex
                                            )
                                          )
                                            ? "hue-rotate(180deg) brightness(200%)"
                                            : alreadyBooked ||
                                              isPitchAvailable ||
                                              isDisabledByTime
                                            ? "brightness(90%) saturate(0%)"
                                            : "",
                                        }}
                                      />
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </>
                      );
                    })}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WeekCalendar;
