
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function ScrollToTop() {
  const location = useLocation();
  const scrollPositions = {}; 

  useEffect(() => {
    const handleBeforeUnload = () => {
      scrollPositions[location.pathname] = window.scrollY;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [location]);

  useEffect(() => {
    const savedScrollPosition = scrollPositions[location.pathname] || 0;

    if (savedScrollPosition === 0) {
      window.scrollTo(0, 0); 
    } else {
      window.scrollTo(0, savedScrollPosition); 
    }
  }, [location]);

  return null;
}

export default ScrollToTop;
