import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import profileIcon from "../../assets/images/defaultProfilePic.svg";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { CheckCircle } from "lucide-react";
import "../index.css";
import {
  fetchUserDatasApi,
  uploadProfilePicApi,
  requestSignupOTPApi,
  verifySignupOTPApi,
  setNewMobileApi,
} from "../../handlers/myaccount/api";
import {
  SetNewPhoneNumber,
} from "../../handlers/common/api";
import axios from "axios";

function Profile() {
  const [userData, setUserData] = useState(() => {
    const storedUserData = localStorage.getItem("userData");
    return storedUserData
      ? JSON.parse(storedUserData)
      : {
        email: "",
        phone: "",
        image: profileIcon,
        selectedImage: null,
      };
  });
  const [loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [newPhoneNumber, setNewPhoneNumber] = useState("");
  const [isEditingPhone, setIsEditingPhone] = useState(false);
  const [otp, setOtp] = useState("");
  const [isVerifying, setIsVerifying] = useState(false);
  const [showOtpField, setShowOtpField] = useState(false);
  const [countryCode, setCountryCode] = useState('+65');

  useEffect(() => {
    fetchAndStoreUserData();
  }, []);


  useEffect(() => {
    const timeoutId = setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);



  function getImageUrl(image, baseUrl) {
    if (image.startsWith('/files')) {

      return `${baseUrl}${image}`;
    } else if (image.startsWith('https://')) {

      return image;
    } else if (image.startsWith('/static')) {

      return image;
    } else {

      return image;
    }
  }

  const fetchAndStoreUserData = () => {
    fetchUserData()
      .then((data) => {
        setUserData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    localStorage.setItem("userData", JSON.stringify(userData));
  }, [userData]);

  useEffect(() => {
    if (selectedImage) {
      localStorage.setItem("selectedImage", selectedImage);
    }
  }, [selectedImage]);

  const fetchUserData = async () => {
    const token = localStorage.getItem("token");
    try {
      const userData = await fetchUserDatasApi(token);
      return userData;
    } catch (error) {
      console.error(error.message);
      return {
        email: "",
        phone: "",
        image: profileIcon,
      };
    }
  };

  const handleProfilePicUpload = async () => {
    try {
      const input = document.createElement("input");
      input.type = "file";
      input.accept = "image/*";

      input.onchange = async (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.readAsDataURL(file);

        reader.onload = async () => {
          setSelectedImage(reader.result);

          try {
            const token = localStorage.getItem("token");
            const imageUrl = await uploadProfilePicApi(file, token);
            console.log(imageUrl);
            // fetchAndStoreUserData();
            toast.success("Image Uploaded Successfully");

            // setUserData((prevData) => ({
            //   ...prevData,
            //   image: imageUrl,
            //   selectedImage: reader.result,
            // }));

            localStorage.removeItem("userData");

            fetchAndStoreUserData()

            // localStorage.setItem(
            //   "userData",
            //   JSON.stringify({
            //     ...userData,
            //     image: imageUrl,
            //     selectedImage: reader.result,
            //   })
            // );
          } catch (error) {
            console.error(error.message);
          }
        };
      };

      input.click();
    } catch (error) {
      console.log(error.message);
    } finally {
      fetchAndStoreUserData();
    }
  };

  const handleChangePhoneNumber = async () => {
    const token = localStorage.getItem("token");
    try {
      if (newPhoneNumber.length == 8) {
        const response = await requestSignupOTPApi(`${countryCode}${newPhoneNumber}`, token);

        if (response) {
          setIsEditingPhone(false);
          setShowOtpField(true);
          toast.success("OTP has been successfully sent");

          // Set showOtpField to false here
          setShowOtpField(true);
        } else {
          toast.warning("Failed to send OTP");
        }
      } else {
        toast.warning("Please enter a phone number with  8 digits.");
      }
    } catch (error) {
      console.error(error.message);
      toast.error("Failed to send OTP");
    }
  };

  const handleVerifyOTP = async () => {
    try {
      const token = localStorage.getItem("token");

      const otpVerificationResponse = await verifySignupOTPApi(
        otp,
        `${countryCode}${newPhoneNumber}`,
        token
      );

      if (otpVerificationResponse) {
        // toast.success("OTP Verified Successfully");
        handleSubmit()
      } else {
        toast.error("Failed to verify OTP");
        console.error("Failed to verify OTP");
      }
    } catch (error) {
      toast.error("An error occurred during OTP verification");
      console.error(error.message);
    }
  };
  const handleChange = (e) => {
    setCountryCode(e.target.value);
  };
  const handleSubmit = async () => {
    try {
      const token = localStorage.getItem("token");

      const response = await axios.post(
        SetNewPhoneNumber,
        {
          phone: `${countryCode}${newPhoneNumber}`,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${token}`,
          },
        }
      );
      console.log(response.data.developer_message.message, "set phone number response");

      if (response.data.developer_message.message == "This Mobile no used another User please enter diffrent mobile no.") {
        toast.success("Mobile Number already used with another user. Try Different Number");
      } else if (response.data.developer_message.message == "Mobile Updated Successfully.") {
        toast.success("Mobile Number verified and updated successfully");
        // console.error("Failed to update phone number after OTP verification");
        setShowOtpField(false);
        // toast.success("Phone number updated successfully");
        setIsVerifying(true);

      } else {
        toast.error("Failed to update phone number");
        console.error("Failed to update phone number after OTP verification");
      }

    } catch (error) {
      toast.error("Failed to update phone number");
    }
  }

  return (
    <div className="px-2">
      <div>
        <img
          src={getImageUrl(userData.image, userData.baseurl)}
          alt="Profile Icon"
          className="w-36 h-36  rounded-full cursor-pointer"
          onClick={handleProfilePicUpload}
        />
        <button
          className="ml-2 px-3 py-1 bg-red-500 mt-4 text-white font-semibold rounded hover:bg-red-600"
          onClick={handleProfilePicUpload}
        >
          Upload
        </button>
      </div>
      <div className="mt-6">
        <label className="text-base font-medium text-gray-900">Email</label>
        <button
          type="button"
          className="flex h-10 w-1/2 rounded-md bg-transparent py-2 text-md placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50 relative"
          value={userData.email}
          readOnly
          style={{
            whiteSpace: 'nowrap',

          }}
        >
          {userData.email}
          {!loading && (
            <FontAwesomeIcon
              icon={faCheckCircle}
              className="text-green-500 ml-4 mt-1"
            />
          )}
        </button>

      </div>
      <div className="mt-6">
        <label className="text-base font-medium text-gray-900">Phone</label>
        {!isEditingPhone ? (
          <div className="flex ">
            <button
              type="button"
              className="flex h-10 rounded-md  bg-transparent  py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50 relative"
              value={userData.phone}
            >
              {newPhoneNumber || userData.phone}
              {!loading && (
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="text-green-500 mt-1 ml-4"
                />
              )}
            </button>

            <div className="" onClick={() => setIsEditingPhone(true)}>
              <FontAwesomeIcon
                icon={faPenToSquare}
                className="text-black-500 ml-4 mt-2"
              />
            </div>
          </div>
        ) : (
          <div className="flex items-center space-x-2 mb-4">
            <select
              id="countrycode"
              name="countrycode"
              value={countryCode}
              onChange={handleChange}
              className=" py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            >
              <option value="+65">+65</option>
              {/* <option value="+91">+91</option> */}
            </select>
            <input
              type="text"
              className="h-10 w-1/2 ml-2 rounded-md py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1"
              placeholder="Enter new phone number"
              value={newPhoneNumber}
              onChange={(e) => setNewPhoneNumber(e.target.value)}
              maxLength={8}
            />
            <button
              className="ml-2 px-3 h-10 py-1 bg-red-500 text-white font-semibold rounded hover:bg-red-600"
              onClick={handleChangePhoneNumber}
            >
              Send OTP
            </button>
          </div>
        )}
      </div>
      {showOtpField && (
        <div className="mt-6">
          <label className="text-base font-medium text-gray-900">
            Enter OTP
          </label>
          <div className="flex items-center">
            <input
              type="text"
              className="h-10 w-1/2 rounded-md border border-gray-300 px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1"
              placeholder="Enter OTP"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
            <button
              className={`ml-2 px-3 h-10 py-1 bg-red-500 text-white font-semibold rounded hover:bg-red-600 ${isVerifying && "bg-green-500"
                }`}
              onClick={handleVerifyOTP}
            // disabled={isVerifying}
            >
              {isVerifying ? (
                <>
                  Verified <CheckCircle className="ml-1" size={12} />
                </>
              ) : (
                "verify"
              )}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Profile;
