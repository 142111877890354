import React, { useState, useEffect } from "react";
import { Switch } from "@mui/material";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import { toast } from "react-toastify";
import {
  fetchUserDataApi,
  sendNotificationSettingsApi,
} from "../../handlers/myaccount/api";

const label = { inputProps: { "aria-label": "Switch demo" } };

function NotificationSettings() {
  const [emailNotification, setEmailNotification] = useState(false);
  const [smsNotification, setSmsNotification] = useState(false);
  const [optOutAll, setOptOutAll] = useState(false); // State for opting out of all notifications
  const [isToastVisible, setIsToastVisible] = useState(false); // State to track toast visibility

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    const token = localStorage.getItem("token");
    try {
      const userData = await fetchUserDataApi(token);
      // Set initial state of emailNotification and smsNotification
      setEmailNotification(userData.data.notification_settings.email === 1);
      setSmsNotification(userData.data.notification_settings.sms === 1);
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleEmailChange = () => {
    if (!isToastVisible) {
      const newEmailNotification = !emailNotification;
      setEmailNotification(newEmailNotification);
      if (!optOutAll) {
        sendNotificationSettings({
          email_flag: newEmailNotification ? 1 : 0,
          sms_flag: smsNotification ? 1 : 0,
        });
      }
    }
  };

  const handleSmsChange = () => {
    if (!isToastVisible) {
      const newSmsNotification = !smsNotification;
      setSmsNotification(newSmsNotification);
      if (!optOutAll) {
        sendNotificationSettings({
          email_flag: emailNotification ? 1 : 0,
          sms_flag: newSmsNotification ? 1 : 0,
        });
      }
    }
  };

  const handleOptOutAllChange = () => {
    if (!isToastVisible) {
      const newOptOutAll = !optOutAll;
      setOptOutAll(newOptOutAll);
      if (newOptOutAll) {
        setEmailNotification(false);
        setSmsNotification(false);
        sendNotificationSettings({ email_flag: 0, sms_flag: 0 }); // Sending 0 for both email and SMS when opting out of all notifications
      } else {
        // If unchecking, set the emailNotification and smsNotification states based on their current values
        setEmailNotification(true);
        setSmsNotification(true);
        // Call sendNotificationSettings with appropriate payload based on current state values
        sendNotificationSettings({
          email_flag: emailNotification ? 1 : 0,
          sms_flag: smsNotification ? 1 : 0,
        });
      }
    }
  };

  const sendNotificationSettings = async (payload) => {
    const token = localStorage.getItem("token");
    try {
      const response = await sendNotificationSettingsApi(payload, token);
      // Show toast and set isToastVisible to true
      setIsToastVisible(true);
      toast.success("Notification settings updated successfully", {
        toastId: "notificationToast", // Set a specific toastId for this message
        autoClose: 3000, // Duration in milliseconds
        onClose: () => setIsToastVisible(false), // Callback when toast is closed
      });
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <div>
      <h2 className="font-bold text-2xl leading-6">
        Receive booking reminder via
      </h2>
      <div>
        <div className="mt-4 flex items-center">
          <span>SMS</span>
          <Switch
            {...label}
            checked={smsNotification}
            onChange={handleSmsChange}
            sx={{
              "& .MuiSwitch-switchBase": {
                color: "white", // Default knob color
              },
              "& .MuiSwitch-switchBase.Mui-checked": {
                color: "white", // Knob color when checked
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 0, 0.08)",
                },
              },
              "& .MuiSwitch-track": {
                backgroundColor: "red", // Default track color
              },
              "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                backgroundColor: "green", // Track color when checked
              },
            }}
            disabled={isToastVisible}
          />
        </div>
        <div className="mt-3">
          <span>Email</span>
          <Switch
            {...label}
            checked={emailNotification}
            onChange={handleEmailChange}
            sx={{
              "& .MuiSwitch-switchBase": {
                color: "white", // Default knob color
              },
              "& .MuiSwitch-switchBase.Mui-checked": {
                color: "white", // Knob color when checked
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 0, 0.08)",
                },
              },
              "& .MuiSwitch-track": {
                backgroundColor: "red", // Default track color
              },
              "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                backgroundColor: "green", // Track color when checked
              },
            }}
            disabled={isToastVisible}
          />
        </div>
        <div className="mt-3">
          <input
            type="checkbox"
            checked={optOutAll}
            onChange={handleOptOutAllChange}
            style={{
              accentColor: "red",
              outlineColor: "red",
            }}
            disabled={isToastVisible}
          />

          <span className="ml-2">Opt out of all notifications</span>
        </div>
      </div>
    </div>
  );
}

export default NotificationSettings;
